.tips {
    position: relative;
    margin-bottom: 30px;
}
.tips::after {
    content: '';
    position: absolute;
    height: 4px;
    width: 100%;
    background: #f2f2f2;
    top: calc(50% - 2px);
    border-radius: 50px;
}
button.button {
    position: relative;
    width: 45px;
    height: 45px;
    padding: 0;
    font-size: 1.4rem;
    text-align: center;
    line-height: 45px;
    border: none;
    box-shadow: 0px 0px 4px rgba(238, 122, 64, 0.25);
    z-index: 1;
}
button.button:global(.btn-outline-secondary) {
    background: white;
}

@media (max-width: 376px) {
    button.button {
        font-size: 1.2rem!important;
        width: 37.33px;
        height: 38.34px;
        line-height: 38.34px;
    }
}
