// size
$font-size-root: 10px;
$font-size-base: 1.6rem;
$font-weight-bold: 900;
$line-height: 1.4;
$border-radius: 1rem;
$border-radius-lg: 2rem;
$spacer: 2rem;

// font
$font-family-base: Lato;

// color
$primary: #ee7a40;
$secondary: #f2f2f2;
$danger: #dc3545;
$min-contrast-ratio: 2.5;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "danger": $danger,
);

// Button
$btn-padding-y: 1.2rem;

// Input
$input-padding-y: 1.2rem;
$input-padding-x: 1rem;
$form-check-input-checked-bg-color: white;
$form-check-input-checked-border-color: #e0e0e0;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-2.5 -2.5 5 5'><circle r='2' fill='#{$primary}'/></svg>");

// Modal
$modal-sm: 400px;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;

@import "bootstrap/scss/bootstrap";

.btn-outline-secondary {
    color: black;
}
